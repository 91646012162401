import React from 'react';
import PropTypes from 'prop-types';
import { TicketSourceOperatorTypes } from '@crema/constants/AppEnums';
import { Box, Tooltip, Typography } from '@mui/material';
const TicketCreatorTypeBubble = ({ ticket }) => {
  return (
    <>
      {ticket?.TicketCreatorType?.ID == TicketSourceOperatorTypes.SystemAdmin && (
        <Tooltip title={ticket?.CreatedByApplicationUser?.FullName} arrow>
          <Box
            sx={{
              width: 30,
              height: 30,
              borderRadius: '50%',
              background: '#074799',
              color: '#fff',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 12,
            }}
          >
            {ticket?.CreatedByApplicationUser?.FullName.slice(0, 2).toUpperCase()}
          </Box>
        </Tooltip>
      )}
      {ticket?.TicketCreatorType?.ID == TicketSourceOperatorTypes.AppUser && (
        <Box
          sx={{
            width: 'fit-content',
            minWidth: 40,
            borderRadius: '20px',
            background: '#E84545',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
            fontSize: 14,
            px: 2,
            py: 1,
          }}
        >
          App User
        </Box>
      )}
      {ticket?.TicketCreatorType?.ID == TicketSourceOperatorTypes.System && (
        <Box
          sx={{
            width: 'fit-content',
            minWidth: 40,
            borderRadius: '20px',
            background: '#222B36',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
            fontSize: 14,
            px: 2,
            py: 1,
          }}
        >
          System
        </Box>
      )}
    </>
  );
};

export default TicketCreatorTypeBubble;

TicketCreatorTypeBubble.propTypes = {
  ticket: PropTypes.object,
};
