import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useAuthUser } from '@crema/hooks/AuthHooks';
import AppLoader from '@crema/components/AppLoader';
import { Box, Card, Collapse, Divider, IconButton, Pagination, Skeleton, Typography } from '@mui/material';
import {
  AdminSystemStatus,
  checkListingStatus,
  CurrencyType,
  determineBackgroundColor,
  determineName,
  getStatusColor,
  PrivateAdDraftTypes,
  PrivateAdTableType,
  PrivateAdTypes,
} from '@crema/constants/AppEnums';
import CustomConfig from '@crema/constants/CustomConfig';
import { CommaSeparator } from '@crema/helpers/Extensions';
import { PiMapPinLight } from 'react-icons/pi';
import { useGlobal } from '@crema/context/AppContextProvider/GlobalContext';
import { MdContentCopy } from 'react-icons/md';
import { IoCopyOutline } from 'react-icons/io5';

const ListingHistory = ({ listingHistory }) => {
  const { showSnackbar } = useGlobal();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  // Pagination Logic
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const paginatedlistingHistory = listingHistory.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <>
      {paginatedlistingHistory.length > 0 && (
        <>
          <Box sx={{ background: '#F5F7F8', p: 2, borderRadius: 2 }}>
            <Typography variant='h4'>Listing History</Typography>

            {paginatedlistingHistory?.map((listingItem, index) => (
              <Card
                key={index}
                sx={{
                  width: '100%',
                  background: '#fff',
                  mt: 2,
                  p: 2,
                  borderRadius: 2,
                  boxShadow: 'none',
                  cursor: 'pointer',
                }}
                variant='outlined'
                onClick={() => {
                  let url;
                  if (listingItem.IsPublished) {
                    url = `/manage-listings?ListingId=${listingItem.ID}`;
                  } else {
                    url = `/crm/pending-listings?ListingId=${listingItem.ID}&PrivateListingTypeId=${listingItem.PrivateAdTableTypeId}`;
                  }
                  window.open(url, '_blank');
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 3,
                    }}
                  >
                    <img
                      src={`${CustomConfig.imagePathUrl + listingItem.Attachments[0]?.CardUrl}`}
                      style={{
                        width: '45px',
                        height: '45px',
                        borderRadius: '5px',
                        objectFit: 'cover',
                      }}
                    />
                    <Box>
                      <Box
                        sx={{
                          mb: 0.5,
                          width: '100%',
                        }}
                      >
                        <Typography variant='h4' style={{ fontWeight: '600' }}>
                          {listingItem.Brand['BrandNameen']} {listingItem?.Model?.['ModelNameen']}{' '}
                          {listingItem?.Year.YearName}
                        </Typography>{' '}
                      </Box>
                      <Box sx={{ display: 'flex', gap: 2, mb: 0.5 }}>
                        <Box
                          sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Typography variant='h5' style={{ fontWeight: '400' }}>
                            {listingItem.ID}
                          </Typography>
                          <IoCopyOutline
                            onClick={() => {
                              showSnackbar('Copied to clipboard', 'success');
                              navigator.clipboard.writeText(listingItem.ID);
                            }}
                          />
                        </Box>
                        <Divider orientation='vertical' flexItem sx={{ height: 'auto' }} />
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                          <span
                            style={{
                              display: 'inline-block',
                              width: '10px',
                              height: '10px',
                              backgroundColor: getStatusColor(checkListingStatus(listingItem)),
                              borderRadius: '50%',
                            }}
                          ></span>
                          <Typography>{checkListingStatus(listingItem)}</Typography>
                        </Box>
                      </Box>

                      <Box sx={{ display: 'flex', gap: 2 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            gap: 0.5,
                            alignItems: 'center',
                          }}
                        >
                          <PiMapPinLight
                            style={{
                              width: '1.2em',
                              height: '1.2em',
                              marginBottom: '1px',
                            }}
                          />

                          <Typography variant='h6' sx={{ fontWeight: 400 }}>
                            {listingItem?.Location?.['LocationNameen']}
                          </Typography>
                        </Box>
                        -
                        <Typography variant='h6' sx={{ fontWeight: 400 }}>
                          {' '}
                          {new Date(listingItem?.SaveDate + 'Z').toLocaleString('en-GB', {
                            timeZone: 'Asia/Baghdad', // Correct time zone for Baghdad
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric',
                          })}
                        </Typography>
                        <Typography variant='h6' sx={{ fontWeight: 400 }}>
                          {' '}
                          {new Date(listingItem?.SaveDate + 'Z').toLocaleString('en-GB', {
                            timeZone: 'Asia/Baghdad',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true,
                          })}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ width: 100 }}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontWeight: 600,
                        backgroundColor: determineBackgroundColor(listingItem),
                        color: '#fff',
                        px: 2,
                        py: 0.5,
                        borderRadius: 1,
                        textAlign: 'center',
                        mb: 1,
                      }}
                    >
                      {determineName(listingItem)}
                    </Typography>
                    <Typography
                      variant='h5'
                      style={{ fontWeight: '600', color: 'red', display: 'flex', justifyContent: 'flex-end' }}
                    >
                      {listingItem.PriceUnit == CurrencyType.Dollar && listingItem.Price > 0
                        ? '$' + CommaSeparator(listingItem.Price)
                        : ''}
                      {listingItem.PriceUnit == CurrencyType.IraqiDinar && listingItem.PriceIQD > 0
                        ? 'IQD ' + CommaSeparator(listingItem.PriceIQD)
                        : ''}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            ))}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 2,
              }}
            >
              <Pagination
                count={Math.ceil(listingHistory?.length / itemsPerPage)}
                page={currentPage}
                onChange={handleChangePage}
                color='secondary'
                sx={{
                  '& .MuiPaginationItem-root': {
                    '&.Mui-selected': {
                      backgroundColor: '#222B36',
                      color: '#fff',
                      '&:hover': {
                        backgroundColor: '#222B36', // Maintain the same color on hover
                      },
                    },
                  },
                }}
              />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default ListingHistory;

ListingHistory.propTypes = {
  listingHistory: PropTypes.array,
};
